@import 'variables';
@import '~bootstrap/scss/bootstrap';
@import '~@fortawesome/fontawesome-free/css/all.css';

img {
    transform: translateZ(0);
}

.font-montserrat {
    font-family: 'Montserrat', sans-serif;
}
.bg-hero {
    background-image: url('../images/bg-hero.png');
    background-size: cover;
    background-position: top center;
}
.bg-hero .invalid-feedback {
    color: #f8d7da;
}
.bg-test {
    background-image: url('../images/bg-test.png');
    background-size: cover;
    background-position: center center;
}
.bg-driving {
    background-image: url('../images/bg-driving.png');
    background-size: cover;
    background-position: center center;
}
.bg-map {
    background-image: url('../images/bg-map.png');
    background-size: cover;
    background-position: center center;
}
#carousel {
    height: 400px;
}

.pt-6 {
    padding-top: 5rem;
}
.pb-6 {
    padding-bottom: 5rem;
}
.py-6 {
    padding-top: 5rem;
    padding-bottom: 5rem;
}
.pt-7 {
    padding-top: 10rem;
}

.mb-n7 {
    margin-bottom: -10rem;
}
.mb-n6 {
    margin-bottom: -7.5rem;
}
.mb-6 {
    margin-bottom: 5rem;
}

.border-map {
    border-right: 1px solid #fff;
}

@media (max-width: 767.98px) {
    .py-6 {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .pt-6 {
        padding-top: 2rem;
    }
    .pb-6 {
        padding-bottom: 2rem;
    }

    .mb-n6 {
        margin-bottom: -3rem;
    }
    .mb-6 {
        margin-bottom: 0;
    }

    .w-75 {
        width: 100% !important;
    }

    #carousel {
        height: 200px;
    }
    #carousel .cloud9-item {
        width: auto;
        height: 125px;
    }

    .border-map {
        border-right: 0 !important;
        border-bottom: 1px solid #fff;
    }
}
