$btn-border-width: 2px;
$primary: #cb4a4c;
$secondary: #6ec1e4;
$yiq-contrasted-threshold: 175;
$font-family-base: 'Lato', sans-serif;
$headings-font-family: 'Montserrat', sans-serif;
$headings-font-weight: bold;
$btn-padding-y: .75rem;
$btn-padding-x: 1.5rem;
$btn-padding-y-sm: .5rem;
$btn-padding-x-sm: 1rem;
$btn-font-weight: bold;
$btn-font-family: $headings-font-family;
